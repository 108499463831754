import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Paragraph from "../../../../../components/paragraph"
import Note from "../../../../../components/note"
import HandDrawing from "../../../../../components/hand-drawing"
import VersImage from "../../../../../images/kurse/andenken-handschrift.jpg"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/02-dichterwerkstatt/hoelderlins-handschrift/zusammenfassung" />
      }
      navigation={navigation}
    >
      <Seo title="Hölderlins Handschrift nachzeichnen" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlins Handschrift nachzeichnen
        </Heading>
        <Paragraph>
          Hölderlins Handschrift zu lesen, ist selbst für Profis eine große
          Herausforderung.
        </Paragraph>
        <Note variant="task">
          <p>
            Versuche die letzten Wörter des Gedichts in Hölderlins Handschrift
            nachzufahren.
          </p>
          <p>
            Auf dem Handy oder Tablet kannst du dafür deinen Finger verwenden.
            Am Computer hältst du die linke Maustaste gedrückt und ziehst den
            Mauszeiger über das Bild.
          </p>
        </Note>
        <HandDrawing
          image={VersImage}
          ratio={0.8}
          onChange={(ref) => {
            setAnswer({
              courseId: "reiseerinnerung",
              chapterId: "02-dichterwerkstatt",
              taskId: "hoelderlins-handschrift",
              answer: ref.getSaveData(),
            })
          }}
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
